import axios from "axios";
import $ from "jquery";
import {errorAlert} from "@/vendor/alerts";

export default class Bank {
  constructor(config, setValue) {
    this.$bik = $(`#${config.bik}`);
    this.bank = config.bank;
    this.bank_inn = config.bank_inn;
    this.bank_address = config.bank_address;
    this.ks = config.ks;
    this.swift = config.swift;
    this.setValue = setValue;
    this.api = `${process.env.VUE_APP_API_SERVER}/api/bank/`;
  }

  setData(res) {
    const bank = `${res.payment} / ${res.data.payment_city}`;
    const bank_address = res.data.address.value;
    const ks = res.data.correspondent_account;
    const swift = res.data.swift;
    const bank_inn = res.data.inn;

    if (bank) {
      this.setValue({ id: this.bank, value: bank });
    }

    if (bank_address) {
      this.setValue({ id: this.bank_address, value: bank_address });
    }

    if (ks) {
      this.setValue({ id: this.ks, value: ks });
    }

    if (swift) {
      this.setValue({ id: this.swift, value: swift });
    }

    if (bank_inn) {
      this.setValue({ id: this.bank_inn, value: bank_inn });
    }
  }
  init() {
    this.$bik.on("change input", () => {
      const bik = this.$bik.val();
      if (!bik || bik.replace(/_+/, "").length < 9) {
        return;
      }

      axios
        .get(`${this.api}${bik}`)
        .then(res => {
          this.setData(res.data);
        })
        .catch(err => {
          errorAlert("Ошибка получения реквизитов. Пожалуйста, попробуйте позже");
        });
    });
  }
}
